
import { SessionService } from './services/session/session.service';
import { slideInAnimation } from './animations/route.animation';
import { Router, RouterOutlet } from '@angular/router';
import {Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Customer Portal';
  showNavbar = false;
  showFooter = false;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private  location: Location,
    private sessionService: SessionService,
  ) {
    translate.use(translate.getBrowserLang());
    translate.setDefaultLang('en');
    router.events.subscribe(val => {
      this.showNavbar = this.location.path() !== '/setup' && this.location.path() !== '/login';
    });
    sessionService.registerCulture(translate.getBrowserLang());
  }
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    if (this.location.path() !== '/setup' && this.location.path() !== '/login' && !window.localStorage.getItem('token' )) {
      this.router.navigate(['/login']);
    }
  }
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.animationState
    );
  }
}
