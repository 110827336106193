import { debounceTime, filter } from 'rxjs/operators';
import { ListResponse } from './../../interfaces/base/response';
import { SearchParam } from './../../interfaces/base/search-param';
import {Subscription, Subject} from 'rxjs';
import { CampaignService } from './../../services/campaign/campaign.service';
import { Campaign } from './../../interfaces/campaign';
import { ApiService } from './../../services/api.service';
import { WizardComponent } from './../../components/wizard/wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  environment = environment;
  searchTerm = '';
  isLoading = true;
  tableIsLoading = false;
  page = 1;
  tableOffset = 0;
  pageLimit = 25;
  dashboardData: any = [];
  update = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public readonly defaultFilterStatus = 'completed';

  public readonly defaultLimit = 10;

  public readonly defaultOffset = 0;

  public readonly defaultMinPage = 0;

  searchParam: SearchParam = {
    filter_status: this.defaultFilterStatus,
    limit: this.defaultLimit,
    offset: this.defaultOffset,
    search: '',
  };

  overallSize = 0;
  hasPrevPage = false;
  hasNextPage = false;
  isLoadingFailed = false;
  currentPage = this.defaultMinPage;
  displayedColumns = ['title', 'audience', 'status', 'trigger'];
  dataSource: MatTableDataSource<Campaign> = new MatTableDataSource<Campaign>();

  private subscribedSubscriptions: Subscription = new Subscription();
  private searchInput$: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private apiService: ApiService,
    private campaignService: CampaignService,
  ) {}

  ngOnInit() {
    this.getDashboardStats();

    this.initSearchSubscription();
    this.findAllCampaigns(this.searchParam);

    window.setInterval(() => {
      this.getDashboardStats();
    }, 100000);
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.subscribedSubscriptions.unsubscribe();
  }

  initSearchSubscription() {
    this.subscribedSubscriptions.add(
      (this.searchSubscription = this.searchInput$
        .pipe(debounceTime(1000))
        .subscribe(() => {
          this.findAllCampaigns(this.searchParam);
        }))
    );
    this.subscribedSubscriptions.add(this.searchInput$);
  }

  findAllCampaigns(searchParam?: SearchParam) {
    this.tableIsLoading = true;
    this.subscribedSubscriptions.add(
        this.campaignService.findAll(searchParam).subscribe(
            (response: ListResponse<Campaign>) => {
              this.isLoading = false;
              this.isLoadingFailed = false;
              this.overallSize = response.overallsize;
              // overall size is counting from 1 but offset is counting from 0.
              // if overall is bigger then offset and returned list is full - means that there are more records.
              this.hasNextPage =
                  response.overallsize - 1 - this.searchParam.offset > 0 &&
                  response.list.length >= this.defaultLimit;
              this.hasPrevPage = this.currentPage > this.defaultMinPage;
              this.dataSource.data = response.list;
              this.tableIsLoading = false;
            },
            () => {
              this.isLoading = false;
              this.tableIsLoading = false;
              this.isLoadingFailed = true;
            }
        )
    );
  }

  getDashboardStats() {
    this.apiService.getDashboard().subscribe(
      (res) => {
        this.isLoading = false;
        this.dashboardData = res;
      },
      (err) => {
        window.localStorage.clear();
        this.router.navigate(['/login']);
      }
    );
  }

  runSearch() {
    this.searchInput$.next();
  }

  filter() {
    this.currentPage = this.defaultMinPage;
    this.searchParam.offset = this.defaultOffset;
    this.searchInput$.next();
  }

  clearFilter() {
    this.currentPage = this.defaultMinPage;
    this.searchParam.offset = this.defaultOffset;
    this.searchParam.create_type = '';
    this.searchInput$.next();
  }

  clearSearch() {
    this.searchParam.search = '';
    this.searchInput$.next();
  }

  reloadPage(emitEvent) {
    if (emitEvent) {
      setTimeout(() => {
        this.findAllCampaigns(this.searchParam);
      }, 500);
    }
  }

  prevPage() {
    if (this.currentPage > this.defaultMinPage) {
      this.currentPage--;
      this.searchParam.offset -= this.defaultLimit;
      this.findAllCampaigns(this.searchParam);
    }
  }

  nextPage() {
    this.currentPage++;
    this.searchParam.offset += this.defaultLimit;
    this.findAllCampaigns(this.searchParam);
  }

  openWizard(first: number, second: number, third: number) {
    const wizard = this.dialog
      .open(WizardComponent, {
        width: '90%',
        maxWidth: '1100px',
        panelClass: 'wizard-panel',
        disableClose: true,
        data: {
          first,
          second,
          third,
        },
      })
        .afterClosed()
        .subscribe((res) => {
          if (res === true) {
            this.update = true;
            setTimeout(() => {
              this.findAllCampaigns(this.searchParam);
            }, 2000);
          }
        });
  }

  updateCampaign(element){
    switch (element.status) {
      case true:
        element.campaign.status = 'publish';
        break;
      case false:
          element.campaign.status = 'draft';
        break;
    }
    this.campaignService.save(element.campaign).subscribe(
      res => {
        this.findAllCampaigns(this.searchParam);
      }
    );
  }
}
