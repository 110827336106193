<h2 mat-dialog-title>{{ "app.logout.headline" | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <h3>{{ "app.logout.content" | translate }}</h3>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-flat-button mat-dialog-close>{{ "app.button.no" | translate }}</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    {{ "app.button.yes" | translate }}
  </button>
</mat-dialog-actions>
