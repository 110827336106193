<title mat-dialog-title class="dialog-title">
  {{ "app.audience.saveDialog.title" | translate }}
</title>
<div class="content">
  <form [formGroup]="audienceForm">
    <div class="field-wrapper">
      <mat-form-field
        class="form-field audience-name-field"
        style="text-align: center"
      >
        <label>
          <input
            matInput
            required
            formControlName="name"
            placeholder="{{
              'app.audience.saveDialog.placeholder.name' | translate
            }}"
          />
        </label>
      </mat-form-field>
    </div>
  </form>
  <mat-accordion class="step-content">
    <mat-expansion-panel
      hideToggle
      [expanded]="true"
      (afterExpand)="activeExpander = customExpanderEnum"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon svgIcon="account-group-outline"></mat-icon>
          {{ "app.audience.saveDialog.placeholder.keywords" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="audienceForm">
        <mat-form-field class="form-field">
          <mat-chip-list #chipList aria-label="Keyword selection">
            <mat-chip
              [removable]="removable"
              [selectable]="selectable"
              (removed)="remove(keyword)"
              *ngFor="let keyword of filterKeywordsControl.value"
            >
              {{ keyword }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="{{
                'app.audience.saveDialog.placeholder.addKeywords' | translate
              }}"
              #keywordInput
              [matAutocomplete]="auto"
              [formControl]="keywordCtrl"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-list>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectAndAdd($event)"
          >
            <mat-option
              *ngFor="let keyword of filteredKeywords | async"
              [value]="keyword"
            >
              {{ keyword }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>

      <mat-error
        class="filter-error"
        *ngIf="
          keywordCtrl.touched &&
          keywordCtrl.value !== null &&
          !keywordCtrl.value.split(':')[1]
        "
      >
        {{ "app.wizard.panel.audience.filter.errors.notSet" | translate }} "{{
          keywordCtrl.value
        }}"
      </mat-error>

      <mat-error
        class="filter-error"
        *ngIf="
          keywordCtrl.touched &&
          keywordCtrl.value !== null &&
          keywordCtrl.value.split(':')[1]
        "
      >
        {{
          "app.wizard.panel.audience.filter.errors.notApplied"
            | translate: { output: keywordCtrl.value }
        }}
      </mat-error>
    </mat-expansion-panel>

    <mat-expansion-panel
      *ngIf="enableAudienceImport"
      hideToggle
      [disabled]="isDisabledUploadAccordion"
      [ngClass]="{ disabled: isDisabledUploadAccordion }"
      (afterExpand)="activeExpander = fileImportExpanderEnum"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon svgIcon="database-import"></mat-icon>
          {{ "app.audience.saveDialog.importFromFile" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <button mat-flat-button color="accent" (click)="uploader.click()">
        <mat-icon svgIcon="database-import"></mat-icon>
        {{ "app.audience.saveDialog.button.importFromCsv" | translate }}
      </button>
      <input
        hidden
        accept=".csv"
        #uploader
        (change)="uploadFile($event)"
        type="file"
        name="csv"
        id="csvImport"
      />

      <div *ngIf="importFile" class="file-metrics">
        <p>
          <strong>
            {{ "app.audience.saveDialog.selectedFile.name" | translate }}
          </strong>
          <br />
          {{ importFile?.name }}
        </p>

        <div>
          <p>
            <strong>
              {{
                "app.audience.saveDialog.selectedFile.keywordsCount" | translate
              }}
            </strong>
            <br />
            {{ importFileKeywordsCount }}
          </p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-dialog-actions>
    <div class="button-group">
      <button mat-flat-button (click)="closeDialog()">
        {{ "app.button.cancel" | translate }}
      </button>
      <button mat-flat-button color="primary"
        [disabled]="audienceForm?.invalid" (click)="save()">
        {{ "app.button.save" | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
