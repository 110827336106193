import { debounceTime } from 'rxjs/operators';
import { ListResponse } from './../../interfaces/base/response';
import { CampaignService } from './../../services/campaign/campaign.service';
import { SearchParam } from './../../interfaces/base/search-param';
import { Campaign } from './../../interfaces/campaign';
import { Subscription, Subject } from 'rxjs';
import { WizardComponent } from './../../components/wizard/wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-automate',
  templateUrl: './automate.component.html',
  styleUrls: ['./automate.component.scss'],
})
export class AutomateComponent implements OnInit, AfterViewInit, OnDestroy {
  searchTerm = '';

  update = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly defaultFilterStatus = 'publish';

  public readonly defaultLimit = 10;

  public readonly defaultOffset = 0;

  public readonly defaultMinPage = 0;

  searchParam: SearchParam = {
    filter_status: this.defaultFilterStatus,
    limit: this.defaultLimit,
    offset: this.defaultOffset,
    search: '',
  };

  pausedSearchParam: SearchParam = {
      filter_status: 'draft',
      limit: this.defaultLimit,
      offset: this.defaultOffset,
      search: '',
  };

  isLoading = true;
  tableIsLoading = false;

  overallSize = 0;
  hasPrevPage = false;
  hasNextPage = false;

  pausedOverallSize = 0;
  pausedHasPrevPage = false;
  pausedHasNextPage = false;



  isLoadingFailed = false;
  currentPage = this.defaultMinPage;
  pausedCurrentPage = this.defaultMinPage;

  displayedColumns = ['title', 'status', 'trigger'];
  dataSource: MatTableDataSource<Campaign> = new MatTableDataSource<Campaign>();
  pausedDataSource: MatTableDataSource<Campaign> = new MatTableDataSource<Campaign>();

  private subscribedSubscriptions: Subscription = new Subscription();
  private searchInput$: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.initSearchSubscription();
    this.findAllCampaigns(0, this.searchParam);
    this.findAllCampaigns(1, this.pausedSearchParam);
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.subscribedSubscriptions.unsubscribe();
  }

  ngAfterViewInit() {
  }

  initSearchSubscription() {
    this.subscribedSubscriptions.add(
      (this.searchSubscription = this.searchInput$
        .pipe(debounceTime(1000))
        .subscribe(() => {
          this.findAllCampaigns(0, this.searchParam);
          this.findAllCampaigns(1, this.pausedSearchParam);
        }))
    );
    this.subscribedSubscriptions.add(this.searchInput$);
  }

  findAllCampaigns(category: number, searchParam?: SearchParam) {
    this.subscribedSubscriptions.add(
      this.campaignService.findAll(searchParam).subscribe(
        (response: ListResponse<Campaign>) => {
          this.isLoading = false;
          this.isLoadingFailed = false;

          switch (category) {
            case 0:
              this.dataSource.data = response.list;
              this.overallSize = response.overallsize;
              this.hasNextPage =
                response.overallsize - 1 - this.searchParam.offset > 0 &&
                response.list.length >= this.defaultLimit;
              this.hasPrevPage = this.currentPage > this.defaultMinPage;
              break;
              case 1:
                this.pausedOverallSize = response.overallsize;
                this.pausedHasNextPage =
                  response.overallsize - 1 - this.searchParam.offset > 0 &&
                  response.list.length >= this.defaultLimit;
                this.pausedHasPrevPage = this.currentPage > this.defaultMinPage;
                this.pausedDataSource.data = response.list;
                break;
          }

        },
        () => {
          this.isLoading = false;
          this.isLoadingFailed = true;
        }
      )
    );
  }

  runSearch() {
    this.searchInput$.next();
  }

  clearSearch() {
    this.searchParam.search = '';
    this.searchInput$.next();
  }

  reloadPage(emitEvent, category: number) {
    if (emitEvent) {
      setTimeout(() => {
        switch (category) {
          case 0:
            this.findAllCampaigns(0, this.searchParam);
            break;
          case 1:
            this.findAllCampaigns(1, this.pausedSearchParam);
            break;
          default:
            break;
        }
      }, 500);
    }
  }

  prevPage(category: number) {
    switch (category) {
      case 0:
        if (this.currentPage > this.defaultMinPage) {
          this.currentPage--;
          this.searchParam.offset -= this.defaultLimit;
          this.findAllCampaigns(0, this.searchParam);
        }
        break;
      case 1:
        if (this.pausedCurrentPage > this.defaultMinPage) {
          this.pausedCurrentPage--;
          this.pausedSearchParam.offset -= this.defaultLimit;
          this.findAllCampaigns(1, this.pausedSearchParam);
        }
        break;
    }
  }

  nextPage(category: number) {
    switch (category) {
      case 0:
        this.currentPage++;
        this.searchParam.offset += this.defaultLimit;
        this.findAllCampaigns(0, this.searchParam);
        break;
      case 1:
        this.pausedCurrentPage++;
        this.pausedSearchParam.offset += this.defaultLimit;
        this.findAllCampaigns(1, this.pausedSearchParam);
        break;
      }
  }

  openWizard(first: number, second: number, third: number) {
    this.dialog
      .open(WizardComponent, {
        width: '90%',
        height: '75%',
        maxWidth: '1100px',
        maxHeight: '600px',
        panelClass: 'wizard-panel',
        disableClose: true,
        data: {
          first,
          second,
          third,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.update = true;
          this.reloadPage(true, 0);
          this.reloadPage(true, 1);
        }
      });
  }
  updateCampaign(element){
    switch (element.status) {
      case true:
        element.campaign.status = 'publish';
        this.campaignService.save(element.campaign).subscribe(
          res => {
            this.findAllCampaigns(0, this.searchParam);
            this.findAllCampaigns(1, this.pausedSearchParam);
          }
          );
        break;
        case false:
          element.campaign.status = 'draft';
          this.campaignService.save(element.campaign).subscribe(
            res => {
              this.findAllCampaigns(0, this.searchParam);
              this.findAllCampaigns(1, this.pausedSearchParam);
            }
          );
          break;
    }
  }
}
