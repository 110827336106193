import { Component, OnDestroy, OnInit } from '@angular/core';
import { AudienceService } from '../../services/audience/audience.service';
import { ListResponse } from '../../interfaces/base/response';
import { Audience } from '../../interfaces/audience';
import { MatTableDataSource } from '@angular/material/table';
import { SearchParam } from '../../interfaces/base/search-param';
import { Subject, Subscription, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SaveAudienceDialogComponent } from '../../components/dialogs/save-audience-dialog/save-audience-dialog.component';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { DeleteConfirmationComponent } from '../../components/dialogs/delete-confirmation/delete-confirmation.component';

import * as fromApp from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Config } from 'src/app/interfaces/config';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss'],
})
export class AudienceComponent implements OnInit, OnDestroy {
  public readonly defaultLimit = 10;

  public readonly defaultOffset = 0;

  public readonly defaultMinPage = 0;

  searchParam: SearchParam = {
    limit: this.defaultLimit,
    offset: this.defaultOffset,
    search: '',
  };
  private searchInput$: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  overallSize = 0;
  isLoading = false;
  hasPrevPage = false;
  hasNextPage = false;
  isLoadingFailed = false;
  currentPage = this.defaultMinPage;
  displayedColumns = ['name', 'created', 'keywords'];
  dataSource: MatTableDataSource<Audience> = new MatTableDataSource<Audience>();

  configStore: Config;

  enableAudienceImport = false;

  private subscribedSubscriptions: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    public snackBar: SnackBarService,
    private translateService: TranslateService,
    private audienceService: AudienceService,
    private store: Store<fromApp.AppState>,
  ) {}

  ngOnInit(): void {
    this.store.select('config').subscribe((res) => {
      this.configStore = res;

      if (res != null && res.features.find((feature) => feature.itemid === 'audience_csv_import')) {
        this.enableAudienceImport = true;
      }
    });
    this.initSearchSubscription();
    this.findAllAudiences(this.searchParam);
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.subscribedSubscriptions.unsubscribe();
  }

  initSearchSubscription() {
    this.subscribedSubscriptions.add(
      (this.searchSubscription = this.searchInput$.pipe(debounceTime(1000)).subscribe(() => {
        this.findAllAudiences(this.searchParam);
      })),
    );
    this.subscribedSubscriptions.add(this.searchInput$);
  }

  findAllAudiences(searchParams?: SearchParam) {
    this.isLoading = true;
    this.subscribedSubscriptions.add(
      this.audienceService.findAll(searchParams).subscribe(
        (response: ListResponse<Audience>) => {
          this.isLoading = false;
          this.isLoadingFailed = false;
          this.overallSize = response.overallsize;
          // overall size is counting from 1 but offset is counting from 0.
          // if overall is bigger then offset and returned list is full - means that there are more records.
          this.hasNextPage =
            response.overallsize - 1 - this.searchParam.offset > 0 &&
            response.list.length >= this.defaultLimit;
          this.hasPrevPage = this.currentPage > this.defaultMinPage;
          this.dataSource.data = response.list;
        },
        () => {
          this.isLoading = false;
          this.isLoadingFailed = true;
        },
      ),
    );
  }

  runSearch() {
    this.searchInput$.next();
  }

  clearSearch() {
    this.searchParam.search = '';
    this.searchInput$.next();
  }

  reloadPage(emitEvent) {
    if (emitEvent) {
      this.findAllAudiences(this.searchParam);
    }
  }

  prevPage() {
    if (this.currentPage > this.defaultMinPage) {
      this.currentPage--;
      this.searchParam.offset -= this.defaultLimit;
      this.findAllAudiences(this.searchParam);
    }
  }

  nextPage() {
    this.currentPage++;
    this.searchParam.offset += this.defaultLimit;
    this.findAllAudiences(this.searchParam);
  }

  openAudienceDialog(audience?: Audience) {
    let audienceCopy: Audience = null;
    if (audience != null) {
      // TODO remove unwrapping and use Assignee
      // the object needs to be cloned to avoid changing it in the table directly
      audienceCopy = JSON.parse(JSON.stringify(audience));
    }
    this.subscribedSubscriptions.add(
      this.dialog
        .open(SaveAudienceDialogComponent, {
          minWidth: '50%',
          minHeight: '50%',
          maxWidth: '1100px',
          maxHeight: '600px',
          disableClose: true,
          data: audienceCopy,
        })
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.findAllAudiences(this.searchParam);
          }
        }),
    );
  }

  openDeleteConfirmationDialog(audience: Audience) {
    this.subscribedSubscriptions.add(
      this.dialog
        .open(DeleteConfirmationComponent, {
          data: {
            title: this.translateService.instant('app.audience.delete.dialog.title'),
            description: this.translateService.instant('app.audience.delete.dialog.description'),
          },
        })
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.deleteAudience(audience);
          }
        }),
    );
  }

  deleteAudience(audience: Audience) {
    this.isLoading = true;
    this.subscribedSubscriptions.add(
      this.audienceService.delete(audience).subscribe(
        () => {
          this.isLoading = false;
          this.snackBar.success(
            this.translateService.instant('app.audience.delete.toaster.success'),
          );
          this.findAllAudiences(this.searchParam);
        },
        (err) => {
          this.isLoading = false;
          const errorStatus = err.error?.status;
          if (errorStatus === 409) {
            this.snackBar.error(
              this.translateService.instant('app.audience.delete.toaster.error.409'),
            );
          } else {
            this.snackBar.error(
              this.translateService.instant('app.audience.delete.toaster.error.default'),
            );
          }
        },
      ),
    );
  }
}
