<div class="background dashboard">
  <div class="container">
    <header>
      <h1>Customer Portal</h1>
      <h2>{{ "app.copyright" | translate }} mogree.</h2>
      <div class="quick-actions">
        <mat-chip>This page is currently in development</mat-chip>
      </div>
      <div class="quick-actions">
        <a
          mat-raised-button
          class="btn-big"
          color="primary"
          [routerLink]="['/dashboard']"
          routerLinkActive="router-link-active">
          <mat-icon svgIcon="arrow-left"></mat-icon> Take me home
        </a>
      </div>
      <img
        class="cloud"
        src="../../../assets/images/setup_image_cloud_left.svg"
        alt=""/>
      <img class="lines" src="../../../assets/images/lines.svg" alt="" />
      <img
        class="paperplane"
        src="../../../assets/images/paperplane.svg"
        alt=""/>
    </header>
  </div>
  <div class="content">
    <div class="container"></div>
  </div>
</div>
<app-footer></app-footer>
