<mat-card [ngClass]="{ 'disabled-for-loading': isLoading }">
  <mat-card-title [ngSwitch]="cardType">
    <span *ngSwitchCase="1">
      {{ "app.login.headline.reset" | translate }}
    </span>
    <span *ngSwitchDefault>
      {{ "app.login.headline.login" | translate }}
    </span>
  </mat-card-title>
  <div [ngSwitch]="cardType">
    <div *ngSwitchCase="1">
      <mat-card-content>
        <form class="forgot-password" [formGroup]="loginForm">
          <mat-form-field class="full-width">
            <mat-label>{{ "app.login.resetMailLabel" | translate }}</mat-label>
            <input
              type="email"
              formControlName="email"
              matInput
              appMatInputAutofocus
            />
            <mat-error *ngIf="email.invalid && email.touched">{{
              "app.login.error.validMail" | translate
            }}</mat-error>
          </mat-form-field>
        </form>
        <button
          class="uppercase"
          mat-raised-button
          color="primary"
          [disabled]="email.invalid"
          (click)="resetPassword()"
        >
          {{ "app.login.resetPassword" | translate }}
        </button>
        <div class="info">
          <button class="link-button" (click)="resetAccount()">
            {{ "app.login.backToLogin" | translate }}
          </button>
        </div>
      </mat-card-content>
    </div>
    <div *ngSwitchCase="0">
      <mat-card-content>
        <p
          class="data-info"
          [innerHTML]="
            'app.login.dataPoliy'
              | translate
                : {
                    link1: 'https://www.mogree.com/impressum/',
                    link2: 'https://www.mogree.com/datenschutz/'
                  }
          "></p>
        <form class="login" [formGroup]="loginForm">
          <mat-form-field class="full-width" [ngClass]="{ hidden: step !== 0 }">
            <mat-label>{{ "app.login.mailLabel" | translate }}</mat-label>
            <input
              type="email"
              formControlName="email"
              matInput
              appMatInputAutofocus
            />
            <mat-error *ngIf="email.invalid && email.touched">{{
              "app.login.error.validMail" | translate
            }}</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width" [ngClass]="{ hidden: step !== 1 }">
            <mat-label>{{ "app.login.passwordLabel" | translate }}</mat-label>
            <input
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              matInput
              appMatInputAutofocus
            />
            <mat-icon
              class="pointer"
              *ngIf="password.value"
              matSuffix
              (click)="hide = !hide"
            >
              {{ hide ? "visibility_off" : "visibility" }}</mat-icon
            >
            <mat-error *ngIf="password.invalid && password.touched">{{
              "app.login.error.password" | translate
            }}</mat-error>
          </mat-form-field>
          <mat-error class="password-error" *ngIf="loginFailed">{{
            "app.login.error.login" | translate
          }}</mat-error>

          <mat-spinner *ngIf="isLoading"></mat-spinner>
        </form>

        <div class="button-group" [ngSwitch]="step">
          <button
            class="uppercase"
            mat-raised-button
            color="primary"
            *ngSwitchCase="0"
            [disabled]="email.invalid"
            (click)="next()"
          >
            {{ "app.button.continue" | translate }}
          </button>
          <div class="login-button-group" *ngSwitchCase="1">
            <button class="back" mat-flat-button color="link" (click)="back()">
              <mat-icon>arrow_back</mat-icon>
            </button>
            <button
              class="uppercase next-button"
              mat-raised-button
              color="primary"
              [disabled]="password.invalid"
              (click)="next()"
            >
              {{ "app.button.login" | translate }}
            </button>
          </div>
        </div>
        <div class="info">
          <span>
            {{ "app.login.userAgreement" | translate }}
            <a href="/">{{ "app.login.agreement" | translate }}</a>
          </span>
          <br />
          <button class="link-button" (click)="resetAccount()">
            {{ "app.login.noLogin" | translate }}
          </button>
        </div>
      </mat-card-content>
    </div>
  </div>
</mat-card>
