import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  accountInfo: any;
  config: any;
  userForm: FormGroup;
  isDisabled: boolean = true;
  isDataAvailable: boolean = false;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService.getConfig().subscribe(
      res => {
        this.config = res.detail;
        this.isDataAvailable = true;
      }
    );

    this.accountInfo = JSON.parse(window.localStorage.getItem('token'));
    this.userForm = this.fb.group({
      name: this.accountInfo.detail.user.name,
      email: this.accountInfo.detail.user.email,
      oldPassword: [''],
      password: ['']
    });
  }
}
