import { SearchParam } from './../../interfaces/base/search-param';
import { ContentService } from './../../services/content/content.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from './../../services/snack-bar/snack-bar.service';
import { CampaignService } from './../../services/campaign/campaign.service';
import { AudienceService } from './../../services/audience/audience.service';
import { Config } from './../../interfaces/config';
import { Audience } from './../../interfaces/audience';
import { ListResponse, DetailResponse } from './../../interfaces/base/response';
import { ConfigService } from './../../services/config/config.service';
import { ImportDialogComponent } from './../dialogs/import-dialog/import-dialog.component';
import { ApiService } from './../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectContentComponent } from './../dialogs/select-content/select-content.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {debounceTime} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {Content} from '../../interfaces/content';

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  DoCheck,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToastComponent } from './toast/toast.component';
import { DatePipe } from '@angular/common';

import CSVFileValidator from 'csv-file-validator';
import * as moment from 'moment';
import 'moment-timezone';
import { MatStepper } from '@angular/material/stepper';

import * as fromApp from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { ContentEditorComponent } from '../content-editor/content-editor.component';

export interface Actions {
  title: string;
  message: string;
  action: ActionDetail;
}

export interface ActionDetail {
  [index: number]: {
    action: string;
    actionContent: string;
    type: string;
    itemid: string;
  };
}

const ACTIONS_DATA: Actions[] = [
  {
    title: 'Super awesome title',
    message: 'with awesome content',
    action: [
      {
        action: 'web',
        actionContent: 'mogree.com',
        type: null,
        itemid: null,
      },
    ],
  },
  {
    title: 'Super awesome title',
    message: 'with two actions!',
    action: [
      {
        action: 'web',
        actionContent: 'mogree.com',
        type: null,
        itemid: null,
      },
      {
        action: 'notification',
        actionContent: null,
        type: 'X-12',
        itemid: 'AKL3210LS',
      },
    ],
  },
];

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        showError: true,
        displayDefaultIndicatorType: false,
      },
    },
  ],
})
export class WizardComponent implements OnInit, DoCheck, OnDestroy {
  contentForm: FormGroup;
  audienceForm: FormGroup;
  sendForm: FormGroup;

  filterGroupFrom: FormGroup;

  selectedValue: string;
  selectedCar: string;

  displayedColumns: string[] = ['title', 'action', 'select'];
  dataSource = ACTIONS_DATA;

  contentPanel = true;

  numberOfActions = 0;

  maxMessageLength = 1024;
  maxMessageLengthVisable = 420;

  actions = [];

  audienceList = [];

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filterCtrl = new FormControl();
  filteredFilters: string[] = [];
  filterObservable: Observable<string[]>;
  appliedFilters = [];
  allFilterOptions = [];

  allAudience = {
    audience_group_id: '0',
    filter_keywords: [],
    type: 0,
  };

  customAudience = false;

  steps = {
    first: 0,
    second: 0,
    third: 0,
  };

  canSend = false;
  today = new Date();
  selectedDate: Date = new Date();

  selectedPushType = 0;
  overallReach: number;
  filteredReach: number;

  importedContent = null;

  sendingCampaign = false;

  datePipe = new DatePipe('en-EN');

  uploadingCSV = false;

  currentStep: number;

  configStore: Observable<Config>;
  configSettings: Config;

  features = {
    import: false,
    images: false,
    template: false,
    placeholder: false,
    automation: false,
  };

  contentTemplates: any;

  placeholders: string[] = [];

  public readonly defaultLimit = 10;

  public readonly defaultOffset = 0;

  public readonly defaultMinPage = 0;

  searchParamContent: SearchParam = {
    limit: this.defaultLimit,
    offset: this.defaultOffset,
    search: '',
  };

  private searchInput$: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  overallSize = 0;
  isLoading = false;
  hasPrevPage = false;
  hasNextPage = false;
  isLoadingFailed = false;
  currentPage = this.defaultMinPage;
  dataSourceSearch: MatTableDataSource<Content> = new MatTableDataSource<Content>();
  pageLimit: number = 10;
  pagesCount: number;


  private subscribedSubscriptions: Subscription = new Subscription();


  contentTemplateSize = 0;

  readonly intervalTypes = ['daily', 'weekly'];

  readonly intervalWeekday = [
    'monday',
    'tuesday',
    'wednesday',
    'thurstday',
    'friday',
    'saturday',
    'sunday',
  ];

  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('uploader') fileInput: ElementRef;
  @ViewChild('nextButton', { static: false }) nextButton: ElementRef;
  @ViewChild('backButton', { static: false }) backButton: ElementRef;

  @ViewChild('stepper') private stepper: MatStepper;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WizardComponent>,
    private apiService: ApiService,
    private configService: ConfigService,
    private audienceService: AudienceService,
    private campaignService: CampaignService,
    private contentService: ContentService,
    public snackBar: SnackBarService,
    private translateService: TranslateService,
    private store: Store<fromApp.AppState>,
  ) {
    this.configStore = this.store.select('config');
  }

  ngOnInit(): void {
    this.initSearchSubscription();
    this.findAllContents(this.searchParamContent);
    if (this.data) {
      this.steps = this.data;
    }

    this.contentService.findAll(this.searchParamContent).subscribe((res) => {
      this.contentTemplates = res.list;
      this.contentTemplateSize = res.overallsize;
      this.contentService.getPlaceholder().subscribe((res) => {
        this.placeholders = res.list;
      });
    });

    this.configStore.subscribe((res) => {
      this.configSettings = res;
      if (
        this.configSettings.features.find((feature) => feature.itemid === 'audience_csv_import')
      ) {
        this.features.import = true;
      }
      if (this.configSettings.features.find((feature) => feature.itemid === 'content_template')) {
        this.features.template = true;
      }
      if (this.configSettings.features.find((feature) => feature.itemid === 'message_image')) {
        this.features.images = true;
      }
      if (
        this.configSettings.features.find((feature) => feature.itemid === 'content_placeholder')
      ) {
        this.features.placeholder = true;
      }
      if (
        this.configSettings.features.find((feature) => feature.itemid === 'trigger_type_interval')
      ) {
        this.features.automation = true;
      }
    });

    this.getAudience(false);

    this.keywordFilter();

    this.contentForm = this.fb.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
      imageUrl: this.fb.group({
        isSet: false,
        url: [''],
      }),
      iconUrl: this.fb.group({
        isSet: false,
        url: [''],
      }),
      interaction: this.fb.array([]),
    });

    this.audienceForm = this.fb.group({
      audience_group_id: '0',
      audience: [this.allAudience],
      customList: [''],
    });

    this.getAudienceCount(false);

    this.sendForm = this.fb.group({
      sendNow: [false],
      schedule: this.fb.group({
        date: [this.today],
        time: [moment(new Date()).format('HH:mm')],
      }),
      trigger: this.fb.group({
        interval_time: [moment(new Date()).format('HH:mm')],
        interval_type: [],
        interval_weekday: [],
        send_time: [this.today],
        trigger_type: [],
      }),
    });

    this.configService.findOne('').subscribe((response: DetailResponse<Config>) => {
      for (let i = 0; i < response.detail.actions.length; i++) {
        const element = response.detail.actions[i];
        if (Object.keys(element.custom_fields).length !== 0) {
          element.key = Object.keys(element.custom_fields);
        }
      }
      this.actions = response.detail.actions;
      this.addDefaultAction();
      if (this.data.row) {
        this.importedContent = this.data.row;
        this.contentForm.patchValue({
          title: this.data.row.content.title,
          message: this.data.row.content.text,
        });

        if (this.data.row.content.imageurl && this.data.row.content.imageurl.length > 8) {
          this.contentForm.patchValue({
            imageUrl: {
              isSet: true,
              url: this.data.row.content.imageurl.slice(8),
            },
          });
        }
        if (this.data.row.content.iconurl && this.data.row.content.iconurl.length > 8) {
          this.contentForm.patchValue({
            iconUrl: {
              isSet: true,
              url: this.data.row.content.iconurl.slice(8),
            },
          });
        }

        if (this.data.row.trigger.trigger_type === 'exact_date') {
          this.sendForm.patchValue({
            schedule: {
              date: new Date(),
              time: this.datePipe.transform(this.data.row.trigger.send_time, 'HH:mm'),
            },
          });
        }
        if (this.data.row.trigger.trigger_type === 'interval') {
          this.sendForm
            .get('trigger')
            .get('trigger_type')
            .patchValue(this.data.row.trigger.interval_type);
          this.sendForm
            .get('trigger')
            .get('interval_time')
            .patchValue(this.data.row.trigger.interval_time);
          this.sendForm
            .get('trigger')
            .get('interval_type')
            .patchValue(this.data.row.trigger.interval_type);
          this.sendForm
            .get('trigger')
            .get('interval_weekday')
            .patchValue(this.data.row.trigger.interval_weekday);
          this.sendForm
            .get('trigger')
            .get('send_time')
            .patchValue(new Date(this.data.row.trigger.send_time));
          this.sendForm
            .get('trigger')
            .get('trigger_type')
            .patchValue(this.data.row.trigger.trigger_type);
          this.sendForm.updateValueAndValidity();
        }

        this.actionForms.removeAt(0);

        let interactionCount = 0;

        this.importedContent.content.interaction.forEach((element) => {
          interactionCount = interactionCount++;
          const action = this.fb.group({
            action: [element.action, Validators.required],
            custom: this.fb.group({
              id: element.custom.link,
            }),
            type: [element.type, Validators.required],
            name: [element.name],
          });
          this.actionForms.push(action);
          this.changeAction(interactionCount);
          this.actionForms.updateValueAndValidity();
        });
        if (this.data.row.audience.filter_keywords.length) {
          this.data.row.audience.filter_keywords.forEach((element) => {
            this.appliedFilters.push(element);
            this.filterInput.nativeElement.value = '';
            this.filterCtrl.setValue(null);
            this.updateCustomList(false);
          });
        }
      }
    });

    this.filterObservable = this.filterCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value)),
    );
  }

  ngOnDestroy(): void {
    this.subscribedSubscriptions.unsubscribe();
  }

  initSearchSubscription() {
    this.subscribedSubscriptions.add(this.searchSubscription = this.searchInput$
        .pipe(debounceTime(1000))
        .subscribe(() => {
            this.findAllContents(this.searchParamContent);
        }));
    this.subscribedSubscriptions.add(this.searchInput$);
  }

  findAllContents(searchParamContent?: SearchParam) {
      this.isLoading = true;
      this.subscribedSubscriptions.add(this.contentService.findAll(searchParamContent).subscribe((response: ListResponse<Content>) => {
          this.isLoading = false;
          this.isLoadingFailed = false;
          this.overallSize = response.overallsize;
          this.hasNextPage = ((response.overallsize - 1) - this.searchParamContent.offset) > 0 && response.list.length >= this.defaultLimit;
          this.hasPrevPage = this.currentPage > this.defaultMinPage;
          this.dataSourceSearch.data = response.list;
          this.pagesCount = (Math.ceil(this.overallSize / this.pageLimit));
      }, () => {
          this.isLoading = false;
          this.isLoadingFailed = true;
      }));
  }

  runSearch() {
      this.searchInput$.next();
  }

  clearSearch() {
      this.searchParamContent.search = '';
      this.searchInput$.next();
  }

  reloadPage(emitEvent) {
      if (emitEvent) {
          this.findAllContents(this.searchParamContent);
      }
  }

  openEditor(){
    this.dialog.open(ContentEditorComponent, {})
    .afterClosed()
    .subscribe((res) => {
      if (res === true) {
        setTimeout(() => {
          this.findAllContents(this.searchParamContent);
        }, 2000);
      }
    });
  }

  prevPage() {
    if (this.currentPage > this.defaultMinPage) {
      this.currentPage--;
      this.searchParamContent.offset -= this.defaultLimit;
      this.findAllContents(this.searchParamContent);
    }
  }

  goToStartPage(){
    if (this.currentPage > this.defaultMinPage) {
      this.currentPage = this.defaultMinPage;
      this.searchParamContent.offset -= this.defaultLimit;
      this.findAllContents(this.searchParamContent);
    }
  }

  nextPage() {
    this.currentPage++;
    this.searchParamContent.offset += this.defaultLimit;
    this.findAllContents(this.searchParamContent);
  }

  ngDoCheck(): void {
    if (this.appliedFilters.length >= 1) {
      this.customAudience = true;
    } else {
      this.customAudience = false;
    }
  }

  get actionForms() {
    return this.contentForm.get('interaction') as FormArray;
  }

  get triggers() {
    return this.sendForm.get('trigger') as FormArray;
  }

  addDefaultAction() {
    if (this.actions && this.actions.length > 0) {
      // get first action by default
      const defaultAction = this.actions[0];
      const actionContent = this.fb.group({
        action: defaultAction.name,
        name: defaultAction.name,
        type: defaultAction.type,
        custom: this.fb.group({
          id: [defaultAction.custom_fields ? defaultAction.custom_fields.itemid : ''],
        }),
      });
      this.actionForms.push(actionContent);
    }
  }

  addActions() {
    const actionContent = this.fb.group({
      action: [this.fb.control({}), Validators.required],
      name: ['', Validators.required],
      type: 9,
      custom: this.fb.group({
        id: [''],
      }),
    });
    this.actionForms.push(actionContent);
  }

  deleteAction(i) {
    this.actionForms.removeAt(i);
  }

  changeAction(i) {
    for (let index = 0; index < this.actions.length; index++) {
      const element = this.actions[index];
      if (element.custom_fields.itemid && element.custom_fields.link) {
        if (this.actionForms.controls[i].get('action').value === element.name) {
          this.actionForms.controls[i].get('custom').get('id').setValidators([
            Validators.required,
          ]);

          this.updateActionFields(i);
          break;
        } else {
          this.actionForms.controls[i].get('custom').get('id').clearValidators();
          this.actionForms.controls[i].get('custom').get('id').reset();
          this.updateActionFields(i);
        }
      }
    }
  }

  updateActionFields(i) {
    this.actionForms.controls[i].get('custom').get('id').updateValueAndValidity();
  }

  selectContent(content) {
    const contentDialog = this.dialog.open(SelectContentComponent);

    contentDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.actionForms.clear();
        this.contentForm.patchValue({
          title: content.title,
          message: content.text,
        });

        content.interaction.forEach((element) => {
          const action = this.fb.group({
            action: element.action,
            actionContent: element.actionContent,
            type: element.type,
            itemid: element.itemid,
          });
          this.actionForms.push(action);
        });
        this.contentPanel = true;
      }
    });
  }

  setPanel(state: boolean) {
    this.contentPanel = state;
  }

  helpDialog() {
    this.snackBar.primary('Coming soon!');
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const format = ':';

    if (value.split(format)[1] && value.split(format)[1].length !== 0) {
      this.appliedFilters.push(value.trim());
      input.value = '';
      this.filterCtrl.setValue(null);
      this.getAudienceCount(true);
    } else {
      input.value = value;
    }
  }

  remove(filter: string, isPrefiltered: boolean): void {
    const index = this.appliedFilters.indexOf(filter);

    if (index >= 0) {
      this.appliedFilters.splice(index, 1);
      this.updateCustomList(isPrefiltered);
    }
    this.getAudienceCount(true);
  }

  requireFilter(value: boolean) {
    if (value) {
      this.filterCtrl.setValidators([Validators.required]);
      this.filterCtrl.updateValueAndValidity();
      this.audienceForm.get('audience').clearValidators();
      this.audienceForm.get('audience').reset('');
      this.audienceForm.get('audience').updateValueAndValidity();
      this.audienceForm
        .get('customList')
        .setValidators([Validators.required, Validators.minLength(1)]);
      this.audienceForm.get('customList').updateValueAndValidity();
    } else {
      this.filterCtrl.clearValidators();
      this.filterCtrl.updateValueAndValidity();
      this.audienceForm.get('audience').setValidators([Validators.required]);
      this.audienceForm.get('audience').updateValueAndValidity();
      this.audienceForm.get('customList').clearValidators();
      this.audienceForm.get('customList').reset('');
      this.audienceForm.get('customList').updateValueAndValidity();
    }
  }

  get checkCustomList() {
    return this.audienceForm.get('customList');
  }

  get checkFilterCtrl() {
    return this.filterCtrl;
  }

  selected(event: MatAutocompleteSelectedEvent, isPrefiltered: boolean): void {
    const format = ':';

    if (event.option.viewValue.split(format)[1].length !== 0) {
      this.appliedFilters.push(event.option.viewValue);
      this.filterInput.nativeElement.value = '';
      this.filterCtrl.setValue(null);
      this.getBothAudienceCount();
    } else {
      this.filterInput.nativeElement.value = event.option.viewValue;
    }
  }

  private _filter(value: string): string[] {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      return this.filteredFilters.filter((option) => option.toLowerCase().includes(filterValue));
    }
  }

  getAudienceCount(isPrefiltered: boolean) {
    if (!isPrefiltered) {
      this.apiService
        .getAudienceCount(
          this.audienceForm.value.audience.filter_keywords,
          this.audienceForm.value.audience.itemid || '0',
        )
        .subscribe((res) => {
          this.overallReach = res.detail.overall_reach;
        });
    } else {
      this.apiService
        .getAudienceCount(this.appliedFilters, this.audienceForm.value.audience.itemid || '0')
        .subscribe((res) => {
          this.filteredReach = res.detail.overall_reach;
        });
    }
  }

  updateCustomList(isPrefiltered: boolean) {
    this.audienceForm.get('customList').patchValue(this.appliedFilters);
    this.audienceForm.updateValueAndValidity();
    this.getBothAudienceCount();
  }

  resetFilter() {
    this.appliedFilters = [];
    this.getBothAudienceCount();
  }

  getBothAudienceCount() {
    this.getAudienceCount(false);
    this.getAudienceCount(true);
  }

  selectDate(e) {
    this.selectedDate = e.value;
  }
  checkPushType(type: number) {
    this.selectedPushType = type;
    switch (this.selectedPushType) {
      case 0:
        this.requireSendFormSendCheckbox();
        this.clearAutomateRequirements();
        this.updateAndValidateSendForm();
        break;
      case 1:
        this.requireSendFormSchedule();
        this.updateAndValidateSendForm();
        break;
      case 2:
        this.requireAutomateTrigger();
        this.requireSendFormSchedule();
        this.updateAndValidateSendForm();
        break;
    }
  }

  clearAutomateRequirements() {
  }

  requireSendFormSendCheckbox() {
    this.sendForm.get('sendNow').setValidators([Validators.requiredTrue]);
    this.sendForm.get('schedule').get('date').clearValidators();
    this.sendForm.get('schedule').get('time').clearValidators();
    this.sendForm.get('trigger').get('interval_type').clearValidators();
    this.sendForm.get('trigger').get('interval_weekday').clearValidators();
    this.sendForm.get('trigger').get('interval_type').updateValueAndValidity();
    this.sendForm.get('trigger').get('interval_weekday').updateValueAndValidity();
    this.sendForm.get('trigger').clearValidators();
  }

  requireSendFormSchedule() {
    this.sendForm.get('sendNow').clearValidators();
    this.sendForm.get('trigger').clearValidators();
    this.sendForm.get('schedule').get('date').setValidators([Validators.required]);
    this.sendForm.get('schedule').get('time').setValidators([Validators.required]);
  }

  requireSendFormTrigger() {
    this.sendForm.get('sendNow').clearValidators();
    this.sendForm.get('schedule').get('date').clearValidators();
    this.sendForm.get('schedule').get('time').clearValidators();
    this.sendForm.get('trigger').setValidators([Validators.required]);
  }

  requireAutomateTrigger() {
    this.sendForm.get('trigger').get('interval_type').setValidators([Validators.required]);
    this.sendForm.get('trigger').get('trigger_type').patchValue('interval');
    this.sendForm.get('trigger').get('interval_type').updateValueAndValidity();
    this.sendForm.get('trigger').get('trigger_type').updateValueAndValidity();
    this.sendForm.get('trigger').updateValueAndValidity();
  }

  updateAndValidateSendForm() {
    this.sendForm.get('sendNow').updateValueAndValidity();
    this.sendForm.get('schedule').get('date').updateValueAndValidity();
    this.sendForm.get('schedule').get('time').updateValueAndValidity();
    this.sendForm.get('trigger').updateValueAndValidity();
  }

  get checkSendForm() {
    return this.sendForm;
  }

  get checkAudienceForm() {
    return this.audienceForm;
  }

  get checkContentForm() {
    return this.contentForm;
  }

  finishWizard() {
    if(!this.sendingCampaign) {
      this.sendingCampaign = true;
      switch (this.selectedPushType) {
        case 0:
          this.postCampaign();
          break;
        case 1:
          this.postCampaign();
          break;
        case 2:
          this.postCampaign();
          break;
      }
    } else {
      console.log('Request Already Sent!');
    }
  }

  openSnackbar() {
    this.snackbar.openFromComponent(ToastComponent, {
      duration: 5000,
      panelClass: ['primary-snackbar'],
      horizontalPosition: 'right',
      data: {
        type: this.selectedPushType,
        schedule: this.sendForm.value,
      },
    });
  }
  checkImageIcon() {
    if (this.contentForm.value.imageUrl.isSet) {
      this.contentForm.get('imageUrl').get('url').setValidators([Validators.required]);
    } else {
      this.contentForm.get('imageUrl').get('url').clearValidators();
    }
    if (this.contentForm.value.iconUrl.isSet) {
      this.contentForm.get('iconUrl').get('url').setValidators([Validators.required]);
    } else {
      this.contentForm.get('iconUrl').get('url').clearValidators();
    }
    if (this.contentForm.value.imageUrl.isSet || this.contentForm.value.iconUrl.isSet) {
      this.maxMessageLength = 50;
      this.maxMessageLengthVisable = 50;
    } else {
      this.maxMessageLength = 1024;
      this.maxMessageLengthVisable = 420;
    }
    this.contentForm.get('iconUrl').get('url').updateValueAndValidity();
    this.contentForm.get('imageUrl').get('url').updateValueAndValidity();
  }
  keywordFilter(keyword?: string) {
    if (keyword === '') {
      keyword = undefined;
    }

    let filterKeywords = this.configSettings.filter_keywords;

    for (let index = 0; index < filterKeywords.length; index++) {
      const element = filterKeywords[index];
      const label = element.itemid;
      const keywords = element.preset_list;

      switch (element.valuetype) {
        case 'preset':
          for (let index = 0; index < keywords.length; index++) {
            const keyword = keywords[index];
            if (element.valuetype === 'preset') {
              const filterWord = `${label}:${keyword}`;
              this.filteredFilters.push(filterWord);
            }
          }
          break;
        case 'date':
          for (let index = 0; index < keywords.length; index++) {
            const keyword = keywords[index];
            if (element.valuetype === 'date') {
              const filterWord = `${label}:${keyword}`;
              this.filteredFilters.push(filterWord);
            }
          }
          break;

        default:
          const filterWord = `${label}:`;
          this.filteredFilters.push(filterWord);
          break;
      }
    }
    this.filteredFilters.sort(function(a, b) {
      return a < b ? -1 : a > b ? 1 : 0;
    });
  }
  postCampaign() {
    let imageUrl = '';
    let iconUrl = '';

    if (this.contentForm.value.iconUrl.isSet) {
      iconUrl = 'https://' + this.contentForm.value.iconUrl.url;
    }
    if (this.contentForm.value.imageUrl.isSet) {
      imageUrl = 'https://' + this.contentForm.value.imageUrl.url;
    }

    this.actionForms.controls[0].get('name').patchValue('default');
    this.actionForms.updateValueAndValidity();

    for (let i = 0; i < this.audienceForm.value.audience.filter_keywords.length; i++) {
      const element = this.audienceForm.value.audience.filter_keywords[i];
      this.appliedFilters.push(element);
    }

    for (let index = 0; index < this.contentForm.value.interaction.length; index++) {
      const element = this.contentForm.value.interaction[index];

      this.actions.forEach((action) => {
        if (action.name === element.action) {
          const keys = Object.keys(element.custom);
          const actionKey = Object.keys(action.custom_fields);

          for (let j = 0; j < keys.length; j++) {
            let key = keys[j]; // key
            const value = element.custom[key]; // data
            delete element.custom[key]; // deleting data with old key
            key = key.replace('id', actionKey[0]); // renaming key
            element.custom[key] = value; // setting data with new key
          }
        }
      });
    }

    const body = {
      itemid: null,
      audience: {
        audience_group_id: this.audienceForm.value.audience.itemid || 0,
        filter_keywords: this.appliedFilters,
      },
      content: {
        additional: null,
        iconurl: iconUrl,
        imageurl: imageUrl,
        interaction: this.contentForm.value.interaction,
        language: 'de',
        text: this.contentForm.value.message,
        title: this.contentForm.value.title,
      },
      trigger: {
        trigger_type: 'now',
        send_time: null,
      },
    };

    if (this.data.row !== undefined) {
      if (this.data.row.status === 'publish') {
        body.itemid = this.data.row.itemid;
      }
    }

    switch (this.selectedPushType) {
      case 1:
        const pipe = new DatePipe('en-US');
        let scheduledDate = pipe.transform(this.sendForm.value.schedule.date, 'yyyy-MM-ddT');

        scheduledDate = scheduledDate + this.sendForm.value.schedule.time;

        scheduledDate = pipe.transform(scheduledDate, 'yyyy-MM-ddTHH:mm:ssZ');

        const today = new Date();
        const selectedDate = new Date(scheduledDate);

        if (selectedDate < today) {
          this.sendingCampaign = false;
          this.snackBar.error(this.translateService.instant('app.wizard.panel.send.error.date'));
        } else {
          body.trigger.trigger_type = 'exact_date';
          body.trigger.send_time = scheduledDate;

          this.campaignService.save(body).subscribe(
            (res) => {
              this.openSnackbar();
              this.dialogRef.close(true);
              this.sendingCampaign = false;
            },
            (err) => {
              this.sendingCampaign = false;
              this.snackbar.openFromComponent(ToastComponent, {
                duration: 5000,
                panelClass: ['warn-snackbar'],
                horizontalPosition: 'right',
                data: {
                  type: 3,
                },
              });
            },
          );
        }

        break;

      case 2:
        const dpipe = new DatePipe('en-US');
        let date = dpipe.transform(
          this.sendForm.get('trigger').get('send_time').value,
          'yyyy-MM-ddT' + this.sendForm.get('trigger').get('interval_time').value,
        );
        this.sendForm
          .get('trigger')
          .get('send_time')
          .patchValue(dpipe.transform(date, 'yyyy-MM-ddTHH:mm:ssZ'));
        this.sendForm.get('trigger').get('send_time').updateValueAndValidity();
        body.trigger = this.sendForm.get('trigger').value;

        this.campaignService.save(body).subscribe(
          (res) => {
            this.openSnackbar();
            this.dialogRef.close(true);
            this.sendingCampaign = false;
          },
          (err) => {
            this.sendingCampaign = false;
            this.snackbar.openFromComponent(ToastComponent, {
              duration: 5000,
              panelClass: ['warn-snackbar'],
              horizontalPosition: 'right',
              data: {
                type: 2,
              },
            });
          },
        );

        break;

      default:
        this.campaignService.save(body).subscribe(
          (res) => {
            this.openSnackbar();
            this.dialogRef.close(true);
            this.sendingCampaign = false;
          },
          (err) => {
            this.sendingCampaign = false;
            this.snackbar.openFromComponent(ToastComponent, {
              duration: 5000,
              panelClass: ['warn-snackbar'],
              horizontalPosition: 'right',
              data: {
                type: 3,
              },
            });
          },
        );
        break;
    }
  }
  getAudience(selectLast: boolean) {
    this.audienceService.findAll().subscribe((response: ListResponse<Audience>) => {
      this.audienceList = response.list;
      if (selectLast === true) {
        this.audienceForm.patchValue({
          audience: this.audienceList[this.audienceList.length - 1],
        });
        this.audienceForm.updateValueAndValidity();
        this.getAudienceCount(false);
      }
    });
  }
  uploadFile(file) {
    const checkUser = {
      headers: [
        {
          name: 'user',
          inputName: 'user',
        },
      ],
    };
    const checkDevice = {
      headers: [
        {
          name: 'device',
          inputName: 'device',
        },
      ],
    };

    let validImport = false;

    CSVFileValidator(file.target.files[0], checkUser).then((userData) => {
      if (userData.inValidMessages.length > 0) {
        CSVFileValidator(file.target.files[0], checkDevice).then((deviceData) => {
          if (deviceData.inValidMessages.length > 0) {
            validImport = false;
            deviceData.data.shift();
            this.openImportDialog(file, validImport, deviceData.data);
          } else {
            validImport = true;
            deviceData.data.shift();
            this.openImportDialog(file, validImport, deviceData.data);
          }
        });
      } else {
        validImport = true;
        userData.data.shift();
        this.openImportDialog(file, validImport, userData.data);
      }
    });
  }

  openImportDialog(file, validImport: boolean, data) {
    const importDialog = this.dialog.open(ImportDialogComponent, {
      width: '500px',
      data: {
        fileName: file.target.files[0].name,
        valid: validImport,
        csvData: data,
      },
    });
    importDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.uploadingCSV = true;
        this.apiService.postCSV(file.target.files[0], 'CSV Import').subscribe((res) => {
          this.getAudience(true);
          this.uploadingCSV = false;
        });
      } else {
        this.fileInput.nativeElement.value = '';
      }
    });
  }

  convertDate(audienceDate) {
    const time = audienceDate + '+0000';
    const zone = moment.tz.guess();

    return moment.tz(time, zone).format('HH:mm - DD.MM.YYYY');
  }

  goBack() {
    this.stepper.previous();
  }

  goForward() {
    this.stepper.next();
  }

  setSelectedPlaceholder(option, content){
    var currentMessage = document.getElementById('setPlaceholder') as HTMLInputElement;
    var startPosition = currentMessage.selectionStart;

    return (content.message.slice(0, startPosition) + '[' + option.itemid  + '|placeholder]' + content.message.slice(startPosition));
  }

  checkForm(event) {
    if (event.value === 'weekly') {
      this.sendForm.get('trigger').get('interval_weekday').setValidators([Validators.required]);
      this.sendForm.get('trigger').get('interval_weekday').updateValueAndValidity();
    } else {
      this.sendForm.get('trigger').get('interval_weekday').clearValidators();
      this.sendForm.get('trigger').get('interval_weekday').reset();
    }
  }
}