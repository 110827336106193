<div class="table">
    <mat-card>
        <mat-table [dataSource]="dataSource" *ngIf="displayedColumns && displayedColumns.length > 0">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> {{'app.audience.table.column.name' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="content">
                        <span>
                            {{row?.name}}
                        </span>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Created Column -->
            <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef>
                    {{'app.audience.table.column.creation_date_time' | translate}}
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="content">
                        {{row?.create_time | localDate: 'short'}}
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Keywords Column -->
            <ng-container matColumnDef="keywords">
                <mat-header-cell *matHeaderCellDef>
                    {{'app.audience.table.column.keywords' | translate}}
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="content">
                        <span>{{row?.filter_keywords?.length}}</span>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row" [cdkDetailRow]="row"
                     [cdkDetailRowTpl]="tpl" (toggleChange)="onToggleChange($event)" (click)="getListLength()">
            </mat-row>
        </mat-table>

        <ng-template #tpl let-element>
            <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
                <div class="content">
                    <div class="left">
                        <h3>{{'app.audience.table.column.name' | translate}}:</h3>
                        <p>{{element?.name}}</p>
                        <div class="buttons">
                            <button mat-icon-button
                                    matTooltipPosition="left"
                                    matTooltip="{{'app.button.editAudience' | translate}}"
                                    (click)="editAudience.emit(element)">
                                <mat-icon svgIcon="circle-edit-outline"></mat-icon>
                            </button>
                            <button class="delete" mat-icon-button
                                    (click)="deleteAudience.emit(element)">
                                <mat-icon svgIcon="trash-can"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="right">
                        <div class="creation-date">
                            <h3>{{'app.audience.table.column.creation_date_time' | translate}}:</h3>
                            <mat-chip-list>
                                <mat-chip color="primary">
                                    {{element?.create_time | localDate: 'short'}}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                        <h3>{{'app.audience.table.column.keywords' | translate}}:</h3>
                        <mat-chip-list>
                            <mat-chip *ngFor="let keyword of element?.filter_keywords" class="keyword-list">
                                {{keyword}}
                            </mat-chip>
                        </mat-chip-list>
                        <button mat-raised-button class="load-more-button" (click)="loadMore($event)" *ngIf="listLength > 10">Load more</button>

                    </div>
                </div>
            </div>
        </ng-template>

        <div class="table-skeleton" *ngIf="tableIsLoading">
            <div class="row" *ngFor="let item of [].constructor(10)">
                <app-skeleton skelHeight="30"></app-skeleton>
            </div>
        </div>

        <div class="error" *ngIf="!tableIsLoading && dataSource.data.length === 0">
            <mat-hint>{{'app.audience.table.not_found_list' | translate}}</mat-hint>
        </div>

        <div class="error" *ngIf="loadingFailed">
            <button class="btn-big" mat-raised-button color="primary" (click)="reload.emit(true)">
                <mat-icon svgIcon="reload"></mat-icon>
                {{'app.button.retry' | translate}}
            </button>
        </div>

    </mat-card>

    <div class="paginator" *ngIf="!tableIsLoading && !loadingFailed">
        <button class="prev" (click)="prevPage.emit()" mat-flat-button [disabled]="!hasPrevPage">
            <mat-icon svgIcon="chevron-left"></mat-icon>
        </button>
        <span>{{currentPage + 1}} / {{overallSize / pageLimit | number: '1.0-0'}}</span>
        <button class="next" (click)="nextPage.emit()" mat-flat-button [disabled]="!hasNextPage">
            <mat-icon svgIcon="chevron-right"></mat-icon>
        </button>
    </div>

</div>
