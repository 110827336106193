import { LocalDatePipe } from './pipes/localDate.pipe';
import { TableCardComponent } from './components/table-cards/table-card/table-card.component';
import { AppInitService } from './services/app-init.service';
import { SkeletonLoaderModule } from './modules/skeleton-loader/skeleton-loader.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './modules/material.module';

import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { FooterComponent } from './components/footer/footer.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CardComponent } from './pages/login/components/card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SetupComponent } from './pages/setup/setup.component';
import { SetupStepsComponent } from './pages/setup/components/setup-steps/setup-steps.component';
import { MatInputAutofocusDirective } from './directives/mat-input-autofocus.directive';
import { NavigationComponent } from './components/navigation/navigation.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { LogoutComponent } from './components/dialogs/logout/logout.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { MatIconRegistry } from '@angular/material/icon';
import { SelectContentComponent } from './components/dialogs/select-content/select-content.component';
import { WizardHelpComponent } from './components/dialogs/wizard-help/wizard-help.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ToastComponent } from './components/wizard/toast/toast.component';
import { AutomateComponent } from './pages/automate/automate.component';
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ImportDialogComponent } from './components/dialogs/import-dialog/import-dialog.component';
import { DeleteConfirmationComponent } from './components/dialogs/delete-confirmation/delete-confirmation.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AudienceComponent } from './pages/audience/audience.component';
import { AudienceTableCardComponent } from './components/table-cards/audience-table-card/audience-table-card.component';
import { SaveAudienceDialogComponent } from './components/dialogs/save-audience-dialog/save-audience-dialog.component';
import { ContentComponent } from './pages/content/content.component';
import { ContentTableCardComponent } from './components/table-cards/content-table-card/content-table-card.component';
import { registerLocaleData } from '@angular/common';
import { ShowRecipientsComponent } from './components/dialogs/show-recipients/show-recipients.component';

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';

import * as fromAppReducer from './store/app.reducer';
import { ContentEditorComponent } from './components/content-editor/content-editor.component';

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

const localeName = localStorage.getItem('locale');

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

export function langInit(translate: TranslateService) {
  if (translate.getBrowserLang() === 'de') {
    return 'de';
  } else {
    return 'en';
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoadingIndicatorComponent,
    LoginComponent,
    FooterComponent,
    CardComponent,
    SnackbarComponent,
    SetupComponent,
    SetupStepsComponent,
    MatInputAutofocusDirective,
    NavigationComponent,
    WizardComponent,
    LogoutComponent,
    SettingsComponent,
    SelectContentComponent,
    WizardHelpComponent,
    NumberFormatPipe,
    ToastComponent,
    AutomateComponent,
    TableCardComponent,
    CdkDetailRowDirective,
    PageNotFoundComponent,
    ImportDialogComponent,
    DeleteConfirmationComponent,
    TruncatePipe,
    AudienceComponent,
    AudienceTableCardComponent,
    SaveAudienceDialogComponent,
    ContentComponent,
    ContentTableCardComponent,
    ShowRecipientsComponent,
    LocalDatePipe,
    ContentEditorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useFactory: createTranslateLoader,
      },
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    SkeletonLoaderModule,
    StoreModule.forRoot(fromAppReducer.appReducer, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: langInit,
    },
    {
      provide: MAT_DATE_LOCALE,
      deps: [TranslateService],
      useFactory: langInit,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private translate: TranslateService,
  ) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
