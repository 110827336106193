<div class="table">
  <mat-card>
    <mat-table [dataSource]="dataSource" *ngIf="!tableIsLoading">
      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef>
          {{ "app.dashboard.table.column.title" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="arrow" svgIcon="chevron-down"></mat-icon>

          <div class="content">
            <span>
              {{ row.content.title }}
            </span>
            <mat-hint>
              {{
                row.content.text.length > 80
                  ? (row.content.text | slice: 0:80) + ".."
                  : row.content.text
              }}
            </mat-hint>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Trigger Column -->
      <ng-container matColumnDef="trigger">
        <mat-header-cell *matHeaderCellDef>
          {{ "app.dashboard.table.column.trigger" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.last_sent_time || row.trigger.send_time | localDate: "short" }}
        </mat-cell>
      </ng-container>

      <!-- Audience Column -->
      <ng-container matColumnDef="audience">
        <mat-header-cell *matHeaderCellDef>
          {{ "app.dashboard.table.column.audience" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row.failed_count >= 0">
            {{ row.audience_reached }}/{{
              row.audience_reached + row.failed_count
            }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Status/Reach Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>
          {{ "app.dashboard.table.column.triggerType" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div [ngSwitch]="row.creatorType">
            <div class="trigger-type" *ngSwitchCase="'web'">
              <ng-container
                *ngIf="
                  row.trigger.trigger_type === 'exact_date' ||
                    row.trigger.trigger_type === 'interval';
                  else user">
                <mat-icon
                  *ngIf="row.trigger.trigger_type === 'exact_date'"
                  [matTooltip]="
                    'app.dashboard.table.data.creatorType.tooltip2' | translate"
                  [matTooltipPosition]="'left'"
                  class="icon-info"
                  svgIcon="alpha-s-box"
                ></mat-icon>
                <span *ngIf="row.trigger.trigger_type === 'exact_date'">
                  {{ "app.dashboard.table.data.creatorType2" | translate }}
                </span>
                <mat-icon
                  *ngIf="row.trigger.trigger_type === 'interval'"
                  [matTooltip]="
                    'app.dashboard.table.data.creatorType.tooltip4' | translate"
                  [matTooltipPosition]="'left'"
                  class="icon-info"
                  svgIcon="alpha-a-box"
                ></mat-icon>
                <span *ngIf="row.trigger.trigger_type === 'interval'">
                  {{ "app.dashboard.table.data.creatorType4" | translate }}
                </span>
              </ng-container>
              <ng-template #user>
                <mat-icon
                  [matTooltip]="
                    'app.dashboard.table.data.creatorType.tooltip1' | translate
                  "
                  [matTooltipPosition]="'left'"
                  class="icon-info"
                  svgIcon="alpha-u-box"
                ></mat-icon>
                {{ "app.dashboard.table.data.creatorType1" | translate }}
              </ng-template>
            </div>
            <div class="trigger-type" *ngSwitchCase="'internapi'">
              <mat-icon
                [matTooltip]="
                  'app.dashboard.table.data.creatorType.tooltip2' | translate
                "
                [matTooltipPosition]="'left'"
                class="icon-primary"
                svgIcon="alpha-s-box"
              ></mat-icon>
              {{ "app.dashboard.table.data.creatorType2" | translate }}
            </div>
            <div class="trigger-type" *ngSwitchCase="'remoteapi'">
              <mat-icon
                [matTooltip]="
                  'app.dashboard.table.data.creatorType.tooltip3' | translate
                "
                [matTooltipPosition]="'left'"
                class="icon-primary"
                svgIcon="alpha-a-box"
              ></mat-icon>
              {{ "app.dashboard.table.data.creatorType3" | translate }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (toggleChange)="onToggleChange($event)"
        (click)="getCampaignStats(row, true)"
        matRipple
        class="element-row"
        [cdkDetailRow]="row"
        [cdkDetailRowTpl]="tpl">
      </mat-row>
    </mat-table>

    <ng-template #tpl let-element>
      <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
        <div class="content">
          <div class="left">
            <h3>{{ "app.dashboard.campaign.content" | translate }}</h3>
            <p>{{ element.content.text }}</p>
          </div>
          <div class="right">
            <div class="flex-container">
              <div class="actions">
                <h3>{{ "app.dashboard.campaign.actions" | translate }}</h3>
                <mat-chip-list [disabled]="true">
                  <div *ngFor="let item of element.content.interaction">
                    <mat-chip>
                      {{ item.action }}
                      {{
                        item.custom.id ? "| " + item.custom.id : item.custom.link || item.custom.link 
                        ? "| " + item.custom.link : item.custom.id
                      }}
                    </mat-chip>
                  </div>
                </mat-chip-list>
              </div>
              <div class="buttons">
                <mat-slide-toggle
                  [matTooltip]="'app.toggle.automation' | translate"
                  matTooltipPosition="left"
                  *ngIf="element.trigger.trigger_type === 'interval'"
                  [checked]="element.status === 'publish'"
                  (change)="toggleAutomation($event, element)"
                ></mat-slide-toggle>
                <button
                  *ngIf="element.creatorType !== 'remoteapi'"
                  mat-icon-button
                  [disableRipple]="true"
                  (click)="openWizard(element)"
                  [matTooltip]="getStatus()"
                  matTooltipPosition="left">
                  <div class="flex" [ngSwitch]="campaignStatus">
                    <div class="flex" *ngSwitchCase="'completed'">
                      <mat-icon svgIcon="content-copy"></mat-icon>
                    </div>
                    <div class="flex" *ngSwitchCase="'publish'">
                      <mat-icon class="calender-edit" svgIcon="calendar-edit"></mat-icon>
                    </div>
                    <div class="flex" *ngSwitchCase="'draft'">
                      <mat-icon svgIcon="calendar-edit"></mat-icon>
                    </div>
                  </div>
                </button>
                <button
                  [matTooltip]="'app.button.deleteCampaign' | translate"
                  matTooltipPosition="left"
                  [disableRipple]="true"
                  *ngIf="element.status === 'publish'"
                  mat-icon-button
                  (click)="deleteCampaign(element.itemid)">
                  <mat-icon svgIcon="trash-can"></mat-icon>
                </button>

                <div>
                  <button
                    *ngIf="element.status === 'completed' && campaignStats.audience_reached !== 0 && !fetchingChart"
                    [matTooltip]="'app.button.refresh' | translate"
                    matTooltipPosition="left"
                    class="refresh"
                    mat-icon-button
                    (click)="getCampaignStats(element)">
                    <mat-icon svgIcon="refresh"></mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="keywords">
              <div class="audience-header">
                <h3>{{ "app.dashboard.campaign.keywords" | translate }}</h3>
                <button
                  *ngIf="element.audience.filter_keywords.length > 2"
                  (click)="showRecipients(element.audience)"
                  mat-icon-button>
                  <mat-icon
                    [matTooltip]="'List all recipients'"
                    [matTooltipPosition]="'right'"
                    class="icon-info"
                    svgIcon="tag-multiple"
                  ></mat-icon>
                </button>
              </div>

              <mat-chip-list [disabled]="true">
                <div
                  *ngFor="
                    let keyword of element.audience.filter_keywords;
                    let i = index
                  ">
                  <mat-chip *ngIf="i < 2">
                    {{ keyword }}
                  </mat-chip>
                </div>
                <mat-chip
                  *ngIf="
                    element.audience.filter_keywords.length &&
                    element.audience.filter_keywords.length - 2 > 0
                  ">
                  +{{ element.audience.filter_keywords.length - 2 }}
                  {{
                    "app.dashboard.campaign.additionalRecipients" | translate
                  }}
                </mat-chip>
                <mat-chip *ngIf="element.audience.audience_group_id === '0'"
                  >All</mat-chip
                >
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div
          class="chart-content"
          *ngIf="element.status === 'publish' ||
            ('completed' && !fetchingChart && showStats)">
          <h3>{{ "app.dashboard.campaign.statistics" | translate }}</h3>

          <div *ngFor="let campaignStat of campaignStats">
            <div class="chart" *ngIf="campaignStat.itemid === element.itemid">
              <div
                class="bar failed_count"
                mat-ripple
                *ngIf="campaignStat.failed_count !== 0"
                [ngStyle]="{width: campaignStat.audience_failed_count_percent + '%'}"
                [matTooltipPosition]="'above'"
                [matTooltipClass]="'chartTooltip'"
                [matTooltip]="
                  'app.dashboard.campaign.failed'
                    | translate
                      : {
                          output: campaignStat.failed_count,
                          total: campaignStat.total,
                          percent:
                            campaignStat.audience_failed_count_percent
                            | number: '1.0-2'
                        }">
                <div class="badge">
                  <span *ngIf="campaignStat.audience_failed_count_percent >= 2">
                    {{ campaignStat.audience_failed_count_percent | number: "1.0-2" }}%
                  </span>
                </div>
              </div>
              <div
                class="bar audience_reached"
                [ngStyle]="{
                  width: campaignStat.audience_reached_percent + '%'
                }"
                [matTooltipPosition]="'above'"
                [matTooltipClass]="'chartTooltip'"
                [matTooltip]="
                  'app.dashboard.campaign.reached'
                    | translate
                      : {
                          output: campaignStat.audience_reached,
                          total: campaignStat.total,
                          percent:
                            campaignStat.audience_reached_percent
                            | number: '1.0-2'
                        }"
                matRipple>
                <div class="badge">
                  <span *ngIf="campaignStat.audience_reached_percent >= 2">
                    {{ campaignStat.audience_reached_percent | number: "1.0-2" }}%
                  </span>
                </div>
              </div>
            </div>

            <div class="chart" *ngIf="campaignStat.itemid === element.itemid">
              <div [ngStyle]="{ width: campaignStat.audience_failed_count_percent + '%'}">
              </div>
              <div
                class="bar audience_not_interacted bar_left_border_radius"
                mat-ripple
                [ngStyle]="{ width: campaignStat.audience_reached_not_interacted_percent + '%'}"
                [matTooltipClass]="'chartTooltip'"
                [matTooltipPosition]="'above'"
                [matTooltip]="
                  'app.dashboard.campaign.not_interacted'
                    | translate
                      : {
                          output: campaignStat.audience_reached_not_interacted,
                          total: campaignStat.total,
                          percent:
                            campaignStat.audience_reached_not_interacted_percent
                            | number: '1.0-2'
                        }">
                <div class="badge">
                  <span *ngIf="campaignStat.audience_reached_not_interacted_percent >= 2">
                    {{ campaignStat.audience_reached_not_interacted_percent | number: "1.0-2" }}%
                  </span>
                </div>
              </div>
              <div
                class="bar audience_disabled"
                mat-ripple
                *ngIf="campaignStat.audience_disabled !== 0"
                [ngStyle]="{width: campaignStat.audience_disabled_percent + '%'}"
                [ngClass]="{'bar_left_border_radius': campaignStat.audience_reached_not_interacted === 0}"
                [matTooltipClass]="'chartTooltip'"
                [matTooltipPosition]="'above'"
                [matTooltip]="
                  'app.dashboard.campaign.disabled'
                    | translate
                      : {
                          output: campaignStat.audience_disabled,
                          total: campaignStat.total,
                          percent:
                            campaignStat.audience_disabled_percent
                            | number: '1.0-2'
                        }">
                <div class="badge">
                  <span *ngIf="campaignStat.audience_disabled_percent >= 2">
                    {{
                      campaignStat.audience_disabled_percent | number: "1.0-2"
                    }}%
                  </span>
                </div>
              </div>
              <div
                class="bar audience_dismissed"
                mat-ripple
                *ngIf="campaignStat.audience_dismissed !== 0"
                [ngStyle]="{width: campaignStat.audience_dismissed_percent + '%'}"
                [ngClass]="{'bar_left_border_radius': campaignStat.audience_reached_not_interacted === 0 && campaignStat.audience_disabled === 0}"
                [matTooltipClass]="'chartTooltip'"
                [matTooltipPosition]="'above'"
                [matTooltip]="
                  'app.dashboard.campaign.dismissed'
                    | translate
                      : {
                          output: campaignStat.audience_dismissed,
                          total: campaignStat.total,
                          percent:
                            campaignStat.audience_dismissed_percent
                            | number: '1.0-2'
                        }">
                <div class="badge">
                  <span *ngIf="campaignStat.audience_dismissed_percent >= 2">
                    {{
                      campaignStat.audience_dismissed_percent | number: "1.0-2"
                    }}%
                  </span>
                </div>
              </div>
              <div
                class="bar audience_opened"
                mat-ripple
                *ngIf="campaignStat.audience_opened !== 0"
                [ngStyle]="{ width: campaignStat.audience_opened_percent + '%' }"
                [ngClass]="{'bar_left_border_radius': campaignStat.audience_reached_not_interacted === 0 && campaignStat.audience_disabled === 0 && campaignStat.audience_dismissed === 0}"
                [matTooltipPosition]="'above'"
                [matTooltipClass]="'chartTooltip'"
                [matTooltip]="
                  'app.dashboard.campaign.opened'
                    | translate
                      : {
                          output: campaignStat.audience_opened,
                          total: campaignStat.total,
                          percent:
                            campaignStat.audience_opened_percent
                            | number: '1.0-2'
                        }">
                <div class="badge">
                  <span *ngIf="campaignStat.audience_opened_percent >= 2">
                    {{ campaignStat.audience_opened_percent | number: "1.0-2" }}%
                  </span>
                </div>
              </div>
            </div>
            <div
              class="chart info-chart"
              *ngIf="campaignStat.itemid === element.itemid">
              <div class="bar audience_general" matRipple></div>
              <span class="info">
                {{ campaignStat.audience_reached + campaignStat.failed_count }}
                recipients
              </span>
            </div>
          </div>
          <div class="color-legend">
            <div
              class="failed"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'big-tooltip'"
              [matTooltip]="'app.dashboard.campaign.colorlegend.failed.description' | translate">
              <div class="box"></div>
              {{ "app.dashboard.campaign.colorlegend.failed.title" | translate }}
            </div>
            <div
              class="reached"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'big-tooltip'"
              [matTooltip]="'app.dashboard.campaign.colorlegend.received.description' | translate">
              <div class="box"></div>
              {{ "app.dashboard.campaign.colorlegend.received.title" | translate }}
            </div>
            <div
              class="not_interacted"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'big-tooltip'"
              [matTooltip]="'app.dashboard.campaign.colorlegend.not_interacted.description' | translate">
              <div class="box"></div>
              {{
                "app.dashboard.campaign.colorlegend.not_interacted.title" | translate
              }}
            </div>
            <div
              class="disabled"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'big-tooltip'"
              [matTooltip]="'app.dashboard.campaign.colorlegend.disabled.description' | translate">
              <div class="box"></div>
              {{
                "app.dashboard.campaign.colorlegend.disabled.title" | translate
              }}
            </div>
            <div
              class="dismissed"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'big-tooltip'"
              [matTooltip]="'app.dashboard.campaign.colorlegend.dismissed.description' | translate">
              <div class="box"></div>
              {{ "app.dashboard.campaign.colorlegend.dismissed.title" | translate }}
            </div>
            <div
              class="openend"
              [matTooltipPosition]="'right'"
              [matTooltipClass]="'big-tooltip'"
              [matTooltip]="'app.dashboard.campaign.colorlegend.opened.description' | translate" >
              <div class="box"></div>
              {{
                "app.dashboard.campaign.colorlegend.opened.title" | translate
              }}
            </div>
          </div>
        </div>
        <div
          class="chart-content"
          *ngIf="!showStats && element.status === 'completed'">
          <h3>{{ "app.dashboard.campaign.statistics" | translate }}</h3>
          <p>{{ "app.dashboard.campaign.noStatistics" | translate }}</p>
        </div>
        <div class="skeleton">
          <app-skeleton *ngIf="fetchingChart" skelHeight="50"></app-skeleton>
        </div>
      </div>
    </ng-template>

    <div class="table-skeleton" *ngIf="tableIsLoading">
      <div class="row" *ngFor="let item of [].constructor(10)">
        <app-skeleton skelHeight="30"></app-skeleton>
      </div>
    </div>

    <div class="error" *ngIf="!tableIsLoading && dataSource.data.length === 0">
      <mat-hint>
        {{ "app.dashboard.table.not_found_list" | translate }}
      </mat-hint>
    </div>

    <div class="error" *ngIf="loadingFailed">
      <button
        class="btn-big"
        mat-raised-button
        color="primary"
        (click)="loadTableData()" >
        <mat-icon svgIcon="reload"></mat-icon>
        {{ "app.button.retry" | translate }}
      </button>
    </div>
  </mat-card>

  <div
    class="paginator"
    *ngIf="!loadingFailed && overallSize / pageLimit !== 0">
    <button
      class="expand-button"
      mat-flat-button
      (click)="expandRows()"
      [ngSwitch]="allRowsExpanded">
      <mat-icon
        *ngSwitchCase="false"
        svgIcon="unfold-more-horizontal"></mat-icon>
      <mat-icon
        *ngSwitchCase="true"
        svgIcon="unfold-less-horizontal"
      ></mat-icon>

      <span *ngSwitchCase="false">
        {{ "app.dashboard.table.paginator.expand" | translate }}
      </span>
      <span *ngSwitchCase="true">
        {{ "app.dashboard.table.paginator.collapse" | translate }}
      </span>
    </button>
    <button
      class="prev"
      (click)="paginator(false)"
      mat-flat-button
      [disabled]="!hasPrevPage">
      <mat-icon svgIcon="chevron-left"></mat-icon>
    </button>

    <span>
      {{ currentPage + 1 }} /
      {{ overallSize / pageLimit | number: "1.0-0" }}
    </span>

    <button
      class="next"
      (click)="paginator(true)"
      mat-flat-button
      [disabled]="currentPage + 1 >= overallSize / pageLimit">
      <mat-icon svgIcon="chevron-right"></mat-icon>
    </button>
  </div>
</div>
