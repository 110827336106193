<nav>
    <div class="container">
        <div class="main-section">
            <a [routerLink]="['/dashboard']" class="logo">
                <img src="../../../assets/images/logo_icon.svg" alt="Customer Portal by mogree">
                <span>
                    {{ (config | async)?.customer.name }}
                </span>
            </a>
           <ul class="nav-menu">
                <li class="nav-item" (click)="toggleBurgerMenu()">
                    <a [routerLink]="['dashboard']"
                        routerLinkActive="router-link-active">{{'app.navigation.dashboard' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="enableContent" (click)="toggleBurgerMenu()">
                  <a [routerLink]="['content']" routerLinkActive="router-link-active">{{'app.navigation.content' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="enableAudience" (click)="toggleBurgerMenu()">
                    <a [routerLink]="['audience']"
                        routerLinkActive="router-link-active">{{'app.navigation.audience' | translate}}</a>
                </li>
                <li class="nav-item" (click)="toggleBurgerMenu()">
                    <a [routerLink]="['automate']"
                        routerLinkActive="router-link-active">{{'app.navigation.automate' | translate}}</a>
                </li>
            </ul>
            <div class="hamburger" (click)="toggleBurgerMenu()">
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
        </div>
        <div class="section">
            <div class="profile-wrapper">
                <div class="profile pointer" [matMenuTriggerFor]="menu" (click)="toggleProfile()">
                    <mat-icon svgIcon="account" color="accent"></mat-icon>
                    <span>
                        {{ username }}
                    </span>
                </div>
                <mat-menu #menu="matMenu">
                    <a mat-menu-item [routerLink]="['account']">
                        <mat-icon>settings</mat-icon>
                        {{'app.navigation.menu.settings' | translate}}
                    </a>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        {{'app.navigation.menu.logout' | translate}}
                    </button>
                </mat-menu>
            </div>
        </div>

    </div>
</nav>